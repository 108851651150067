import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { Title }     from '@angular/platform-browser';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css'],
animations: [
    trigger('logoFade', [
      transition('* => fadeIn', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 }))
      ])
      ,
      transition('* => fadeOut', [
        animate(6000, style({ opacity: 0 }))
        , style({ opacity: 0 })
      ])
    ])
  ]
})

export class IntroComponent implements OnInit {

  bindingVar = 'fadeOut';
  hideLogo = false;

    public constructor(private titleService: Title) { }

  ngOnInit() {
        this.titleService.setTitle("Introduction | PowerPro Systems")

       setTimeout(()=> {this.hideLogo = true;console.log(this.hideLogo); } , 5500)
       console.log(this.hideLogo);
  }

  // fadeInOut = "";

  // fadeIn() { this.fadeInOut = "fadeIn" };
  // fadeOut() { this.fadeInOut = "fadeOut" };

  // toggle() {
  //   this.fadeInOut == 'fadeOut' ? this.fadeIn() : this.fadeOut();
  // }

//  fadeInOut = '';
//   fadeIn() {
//     this.fadeInOut = 'fadeIn';
//   }
//   fadeOut() {
//     this.fadeInOut = 'fadeOut';
//   }
//   toggle() {
//     this.fadeInOut == 'fadeOut' ? this.fadeIn() : this.fadeOut();
//   }

ToggleHidden() {
  
}

 
  fadeIn() {
    this.bindingVar = 'fadeIn';
  }
  fadeOut() {
    this.bindingVar = 'fadeOut';
  }
  toggle() {
   // this.bindingVar == 'fadeOut' ? this.fadeIn() : this.fadeOut();
    console.log("bindingVar is now: " + this.bindingVar);
  }



}
