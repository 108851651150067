import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  //title = 'unassigned';
  //meta = "unassigned";

  public constructor(private titleService: Title, private meta: Meta) { }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
    
  }

  public setMetaDescr(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
  }

  

}
