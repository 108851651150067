import { Component, OnInit } from '@angular/core';
import { Title }     from '@angular/platform-browser';

@Component({
  selector: 'app-clientcomments',
  templateUrl: './clientcomments.component.html',
  styleUrls: ['./clientcomments.component.css']
})
export class ClientcommentsComponent implements OnInit {

 public constructor(private titleService: Title) { }


  ngOnInit() {
    this.titleService.setTitle("Client Comments | PowerPro Systems")
  }


}
