import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
//import { HttpModule } from '@angular/http';



import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IntroComponent } from './intro/intro.component';
import { HomeComponent } from './home/home.component';
import { ClientcommentsComponent } from './clientcomments/clientcomments.component';
import { ServicesComponent } from './services/services.component';
import { ContactComponent } from './contact/contact.component';
import { IpComponent } from './ip/ip.component';
import { ContactcollapseComponent } from './contactcollapse/contactcollapse.component';

@NgModule({
  declarations: [
    AppComponent,
    IntroComponent,
    HomeComponent,
    ClientcommentsComponent,
    ServicesComponent,
    ContactComponent,
    IpComponent,
    ContactcollapseComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule 
    //HttpModule
  ],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule {

  pageTitle = "PowerPro Systems | Services"

}
