import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IntroComponent } from './intro/intro.component';
import { HomeComponent } from './home/home.component';
import { ClientcommentsComponent } from './clientcomments/clientcomments.component';
import { ServicesComponent } from './services/services.component';
import { ContactComponent } from './contact/contact.component';
import { IpComponent } from './ip/ip.component';

const routes: Routes = [
   {
    path: '', component: IntroComponent
    //, pathMatch: 'full'
    //children: []
  },

{path: 'ip', component: IpComponent},
{path: 'intro', component: IntroComponent},
{path: 'contact', component: ContactComponent},
{path: 'services', component: ServicesComponent},
{path: 'clientcomments', component: ClientcommentsComponent},
  {path: 'home', component: HomeComponent}

  //{path: 'index.html', pathMatch: 'prefix', component: LoginComponent}
  //{path: 'ind
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
