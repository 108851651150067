import { Component, OnInit } from '@angular/core';
import { Title, Meta }     from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

   public constructor(private titleService: Title, private meta: Meta) { }


  ngOnInit() {
    this.titleService.setTitle("Home - Custom Programming in the Office and Cloud | PowerPro Systems");


  }


}
