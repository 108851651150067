import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contactcollapse',
  templateUrl: './contactcollapse.component.html',
  styleUrls: ['./contactcollapse.component.css']
})
export class ContactcollapseComponent implements OnInit {

  public contactDisplay = "block"; //"none";

  constructor() { }

  ngOnInit() {
  }


  collapseshow() {
    console.log("contactDisplay was " + this.contactDisplay)
    if (this.contactDisplay == "none") {
      this.contactDisplay = "block";
    } else {
      this.contactDisplay = "none";
    }
    console.log("contactDisplay NOW " + this.contactDisplay)

  }


}
