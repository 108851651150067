import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  public isCollapsed = false;
  public contactDisplay = "none";

  public constructor(private titleService: Title) { }



  ngOnInit() {
    this.titleService.setTitle("Contact | PowerPro Systems")
  }


  collapseshow() {
    console.log("contactDisplay was " + this.contactDisplay)
    if (this.contactDisplay == "none") {
      this.contactDisplay = "block";
    } else {
      this.contactDisplay = "none";
    }
    console.log("contactDisplay NOW " + this.contactDisplay)

  }


}
