import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
//import { Console } from 'console';
//import { debug } from 'console';
//import { Http, RequestOptions, Headers } from '@angular/http';

@Component({
  selector: 'app-ip',
  templateUrl: './ip.component.html',
  styleUrls: ['./ip.component.css']
})
export class IpComponent implements OnInit {

  ipAddress: any;


  // interface ItemsResponse {
  // ip: string;
  // }

  constructor(private titleService: Title, private http: HttpClient) {
    this.http.get('https://jsonip.com')
      .subscribe(
        (value: any) => {
          console.log(value);
          this.ipAddress = value.ip;
        },
        (error) => {
          console.log(error);
        }
      )

    //     data => {
    //   //this.ipAddress = data._body["ip"];
    //   //debugger;
    //   this.ipAddress = JSON.parse(data["_body"]).ip;
    //   //.map(data => data.json().ip);
    //   console.log(data);
    //   //debugger;
    // })
  }

  // 1
  //   this.http.get<ItemsResponse>('https://jsonip.com')

  ngOnInit() {
    this.titleService.setTitle("Get My IP | PowerPro Systems")
  }

}
